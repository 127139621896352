<template>
  <div class="nothing-found">
    <r-row class="px-4">
      <r-col :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        <div class="nothing-found__content">
          <div class="harakiri--text brie">
            {{ $t('misc.nothing_found') }}
          </div>

          <div class="metropolis--text feta">
            {{ text }}
          </div>
        </div>
      </r-col>
    </r-row>
  </div>
</template>

<script>
export default {
  name: "NothingFound",

  props: {
    text: {
      type: String,
      default() {return this.$t('misc.try_other')}
    }
  }
}
</script>

<style lang="scss">
.nothing-found {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 30%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .brie {
      margin-bottom: 4px;
    }
  }
}
</style>
