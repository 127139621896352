<template>
  <div>
    <NothingFound text="К сожалению, такой страницы нет :(" />
  </div>
</template>

<script>
import NothingFound from '@/components/Miscellaneous/NothingFound';
export default {
  name: 'NotFound',

  components: {
    NothingFound
  },
};
</script>

<style scoped>

</style>
